import React, { useState } from 'react';
import axios from 'axios';
import './App.css';

function App() {

  const [usernameReg, setUsernameReg] = useState('');
  const [passwordReg, setPasswordReg] = useState('');

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [loginStatus, setLoginStatus] = useState('');
  
  const register = () => {
    axios.post("http://127.0.0.1:3306/register", {
      username: usernameReg, 
      password: passwordReg
    }) 
    .then((response)=> {
      console.log(response);
    });
  }

  const login = () => {
    axios.post("http://127.0.0.1:3306/login", {
      username: username, 
      password: password,
    }) 
    .then((response)=> {

      if(response.data.message) {
        setLoginStatus(response.data.message)
      }else{
        // setLoginStatus("username:" + response.data[0].username + " password:" + password)
        setLoginStatus("username:" + response.data[0].username + " password:" + response.data[0].password)
      }
       
    });
  }

  return (
    <div className='App'> 
     <div className='registration'>
      <h1>Registration</h1>
       
      <input type='text' placeholder="Enter username" onChange={(e) =>{setUsernameReg(e.target.value);
      }} /><br/> 
       
      <input type='text' placeholder="Enter Password" onChange={(e) =>{setPasswordReg(e.target.value);
      }} />
      <div><button className='button-33' onClick={register}>Register</button></div>
      
     </div>

      <div className='login'>
      <h1>Login</h1>
      <input type='text' placeholder="Enter username.." onChange={(e) =>{setUsername(e.target.value);
      }} 
      /><br/>
      <input type='password' placeholder="Enter Password" onChange={(e) =>{setPassword(e.target.value);
      }}  />
      <div><button className='button-33' onClick={login}>Login</button></div>
     </div>

     <h1>{loginStatus}</h1>
     </div>

     
  );
}

export default App;
